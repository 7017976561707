<template>
  <v-card>
    <v-card-title class="justify-space-between mb-0 py-7">
      <span class="text-h5"><strong>Tissue Disposition</strong></span>
    </v-card-title>
    <v-row class="ml-2 mr-2 mb-3">
      <v-col cols="12" md="3">
        <v-text-field v-model="filter.tissue_id" label="Tissue ID" outlined dense hide-details></v-text-field>
      </v-col>
      <v-col cols="12" md="3">
        <v-text-field v-model="filter.intent" label="Intent" outlined dense hide-details></v-text-field>
      </v-col>
      <v-col cols="12" md="3">
        <v-text-field label="Outcome" v-model="filter.outcome" outlined dense hide-details></v-text-field>
      </v-col>
      <v-col cols="12" md="3" class="pl-0 text-right">
        <v-btn color="primary" class="mr-2" @click="AllSearch()"> Search</v-btn>
        <v-btn color="primary" @click="refreshData()">Reset</v-btn>
      </v-col>
    </v-row>

    <v-data-table :headers="headers" :items="AllDataview" :items-per-page="10" fixed-header height="400"
      class="table-rounded">
    </v-data-table>

    <v-snackbar v-model="snackbar" :timeout="timeout">
      {{ snackbarText }}

      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar = false"> Close </v-btn>
      </template>
    </v-snackbar>
  </v-card>
</template>

<script>
import Allservice from '@/service/Tissue.service'
import { api } from '@/config/config'
const localStoragedata = (JSON.parse(localStorage.getItem('token')) === null) ? window.location.href = '/' : JSON.parse(localStorage.getItem('token'));
const token = localStoragedata.token
export default {
  data() {
    return {
      expanded: [],
      singleExpand: true,
      Donor_name: '',
      Tissue_no: '',
      listLoading: false,
      totallist: 0,
      snackbar: false,
      snackbarText: '',
      timeout: 2000,
      AllDataview: [],
      filter: {
        tissue_id: '',
        intent: '',
        outcome: '',
      },

      headers: [
        { text: 'Tissue ID', value: 'tissue_id', width: '150px' },
        { text: 'Intent ', value: 'intent_value', width: '200px' },
        { text: 'Outcome ', value: 'outcome', width: '100px' },
        { text: 'Reason(s) Not Released or Transplant ', value: 'reason_not_released_or_transplanted', width: '150px' },


        // { text: 'Date of transplant', value: 'date_of_transplant', width: '150px' },
        //  { text: 'OPERATE', value: 'operate', width: '250px' },
      ],
    }
  },
  watch: {
    options: {
      handler() {
        this.RecipientView()
      },
      deep: true,
    },
  },

  async mounted() {
    this.RecipientView()
  },

  methods: {

    refreshData(){
    this.filter.tissue_id = ''
    this.filter.intent = ''
    this.filter.outcome = ''
    this.RecipientView()
    },


    async RecipientView() {
      this.listLoading = true
      const service = new Allservice()
      let response = await service.AllListDisposition()
      if (response) {
        this.AllDataview = response
        // console.log("Hello Bill2",this.AllDataview)
        this.totallist = this.AllDataview.length
          (this.filter.tissue_id = ''),
          (this.filter.intent = ''),
          (this.filter.outcome = '')
      } else {
        this.AllDataview = []
        this.totallist = 0
      }
    },

    async AllSearch() {
      this.listLoading = true
      const data = {
        tissue_id: this.filter.tissue_id,
        intent: this.filter.intent,
        outcome: this.filter.outcome,
      }

      if (this.filter.tissue_id == '' && this.filter.intent == '' && this.filter.outcome == '') {
        this.snackbarText = 'Kindly Enter The Keyword',
          this.snackbar = true
        return;
      }

      try {
        const response = await api.post(`TissueDisposition/search_tissue_disposition`, data, {
          headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        })
        if (response) {
          this.AllDataview = response.data
          this.totallist = this.AllDataview.length
        } else {
          this.AllDataview = []
          this.totallist = 0
        }
        this.listLoading = false
      } catch (e) {
        console.log(e)
      }
    },
  },
}
</script>
